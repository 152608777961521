<template>
    <v-card
        block
        outlined
        elevation="0"
        :href="(item.adFile) ? item.adFile.url : null"
        target="_blank"
        class="banner-visual-card"
    >
        <div class="image">
            <v-img
                :src="item.thumbnail.url"
                v-if="item.thumbnail"
            ></v-img>
            <p v-else class="icon mb-0 text-center"><v-icon color="primary">fal fa-ad</v-icon></p>
        </div>

        <p class="text-center ma-3">
            {{ item.title }}
        </p>
        <v-card-actions class="align-center grey lighten-3">
            <v-btn text small color="primary">Download</v-btn>
            <v-spacer></v-spacer>
            <v-btn icon color="primary"><v-icon>fal fa-download</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "AdCard",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
