<template>
    <v-card
        block
        outlined
        elevation="0"
        @click="downloadWithAxios(item.thumbnail.originalUrl, item.thumbnail.title)"
        target="_blank"
        class="product-photo-card"
    >
        <div class="image">
            <v-img
                :src="item.thumbnail.url"
                v-if="item.thumbnail"
            ></v-img>
            <p v-else class="icon mb-0 text-center"><v-icon color="primary">fal fa-images</v-icon></p>
        </div>

        <p class="text-center ma-3">
            {{ item.title }}
        </p>
        <v-card-actions class="align-center grey lighten-3">
            <v-btn text small color="primary">Download</v-btn>
            <v-spacer></v-spacer>
            <v-btn icon color="primary"><v-icon>fal fa-download</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'

export default {
    name: "ProductPhotoCard",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        downloadWithAxios(url, title) {
            if(this.item.photoFile) {
                url = this.item.photoFile.url;
                title = this.item.photoFile.name;
            }

            axios({
                method: 'get',
                url,
                responseType: 'blob',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        }
    }
}
</script>

<style scoped>

</style>
