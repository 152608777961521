<template>
    <div class="marketing-product-photo-overview">
        <div class="row align-center">
            <v-col class="text-right">
                <strong class="px-4 flex-fill">Filters:</strong>
            </v-col>
            <v-col cols="5">
                <v-row>
                    <v-col>
                        <v-select
                            :items="applications"
                            label="Application"
                            v-model="selectedApplications"
                            outlined
                            flat
                            multiple
                            clearable
                            hide-details
                            :menu-props="{
                               closeOnContentClick: true,
                               nudgeBottom: 58
                            }"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            :items="formats"
                            label="Format"
                            v-model="selectedFormats"
                            outlined
                            flat
                            multiple
                            clearable
                            hide-details
                            :menu-props="{
                               closeOnContentClick: true,
                               nudgeBottom: 58
                            }"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </div>

        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="item in paginatedItems" :key="'item-animation-video-'+item.id">
                <ProductPhotoCard :item="item"></ProductPhotoCard>
            </v-col>
        </v-row>

        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="filteredItems.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </div>
</template>

<script>
import ProductPhotoCard from "@/components/marketing/ProductPhotoCard";

export default {
    name: "ProductPhotoOverview",
    components: {ProductPhotoCard},
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        selectedApplications: null,
        selectedFormats: null,
        pagination: {
            pageNumber: 1,
            itemsPerPage: 12
        }
    }),
    computed: {
        filteredItems() {
            if((this.selectedApplications && this.selectedApplications.length > 0) && (this.selectedFormats && this.selectedFormats.length > 0)) {
                return this.items.filter((item) => {
                    return this.selectedApplications.includes(item.application) && this.selectedFormats.includes(item.format);
                });
            } else if(this.selectedApplications && this.selectedApplications.length > 0) {
                return this.items.filter((i) => this.selectedApplications.includes(i.application));
            } else if(this.selectedFormats && this.selectedFormats.length > 0) {
                return this.items.filter((i) => this.selectedFormats.includes(i.format));
            }

            return this.items
        },
        pages() {
            return Math.ceil(this.filteredItems.length / this.pagination.itemsPerPage)
        },
        paginatedItems() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.filteredItems.slice(start, end);
        },
        applications() {
            let applications = [];

            this.items.forEach((item) => {
               applications.push({
                   text: this.getApplicationLabel(item.application),
                   value: item.application
               });
            });

            return applications;
        },
        formats() {
            let formats = [];

            this.items.forEach((item) => {
               formats.push({
                   text: item.format.toUpperCase(),
                   value: item.format
               });
            });

            return formats;
        }
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        },
        getApplicationLabel(application) {
            switch (application) {
                case 'climate':
                    return 'Climate';
                case 'feeding':
                    return 'Feeding';
                case 'monitoring':
                    return 'Monitoring';
            }

            return '';
        }
    }
}
</script>

<style scoped>

</style>
