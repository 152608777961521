<template>
    <v-expansion-panel elevation="0">
        <v-expansion-panel-header class="px-0">
            <span class="date">{{ item.date|luxon('dd.MM.yyyy') }}</span> {{ item.title }}
            <template v-slot:actions><v-icon color="accent">fal fa-angle-down</v-icon></template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div v-html="item.content" class="cms-content"></div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "PressReleaseCard",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
