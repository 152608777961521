<template>
    <v-list-item @click="openPage" :class="{ active: isActive }">
        <v-list-item-icon class="my-0 mr-1">
            <v-icon color="accent">fal fa-angle-right</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title v-text="page.title"></v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "NavigationItem",
    props: {
        page: Object,
    },
    data: () => ({
        expanded: false,
    }),
    computed: {
        isActive () {
            return this.page.slug === this.$route.params.slug;
        },
    },
    methods: {
        openPage () {
            let currentSlug = this.$route.params.slug;
            if (Array.isArray(currentSlug)) {
                currentSlug = currentSlug.join('/');
            }
            let slug = this.page.slug;
            if (Array.isArray(slug)) {
                slug = slug.join('/');
            }
            if (currentSlug !== slug) {
                this.$router.push({name: 'MarketingPageDetail', params: {slug: slug}});
            }
        },
    },
}
</script>

<style scoped>

</style>
