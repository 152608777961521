<template>
    <div class="marketing-press-release-overview">
        <v-expansion-panels elevation="0">
            <PressReleaseCard :item="item" v-for="item in paginatedItems" :key="'item-press-release-'+item.id"></PressReleaseCard>
        </v-expansion-panels>

        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="items.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </div>
</template>

<script>
import PressReleaseCard from "@/components/marketing/PressReleaseCard";

export default {
    name: "PressReleaseOverview",
    components: {PressReleaseCard},
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        pagination: {
            pageNumber: 1,
            itemsPerPage: 10
        }
    }),
    computed: {
        pages() {
            return Math.ceil(this.items.length / this.pagination.itemsPerPage)
        },
        paginatedItems() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.items.slice(start, end);
        }
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        }
    }
}
</script>

<style scoped>

</style>
