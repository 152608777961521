<template>
    <v-container v-if="page">
        <div class="d-md-flex mb-8 line-bottom pb-4">
            <p class="text-h1 mb-3 mb-md-0">Marketing - {{ getCategoryTitle(page.category) }}</p>
            <v-breadcrumbs :items="breadcrumbs" class="ml-auto pa-0"></v-breadcrumbs>
        </div>

        <v-row>
            <v-col cols="12" md="9" v-show="isLoading" class="order-2 order-md-1">
                <div class="text-center mt-5">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </div>
            </v-col>
            <v-col cols="12" md="9" class="order-1 pl-md-5 order-md-2" ref="marketingPageContent">
                <div v-if="page">
                    <h1 class="text-h4 text-fancom-green mb-4">{{ page.title }}</h1>
                    <div v-html="page.content" class="marketing-page-content cms-content"></div>

                    <v-divider class="my-5"></v-divider>

                    <PortalMarketingItemOverview item-type="logo" v-if="page.template === 'logo_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="sticker" v-if="page.template === 'sticker_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="corporate_presentation" v-if="page.template === 'corporate_presentation_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="gated_content" v-if="page.template === 'gated_content_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="press_release" v-if="page.template === 'press_release_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="brochure" v-if="page.template === 'brochure_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="event" v-if="page.template === 'event_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="promotional_product" v-if="page.template === 'promotional_product_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="product_display" v-if="page.template === 'product_display_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="animation_video" v-if="page.template === 'animation_video_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="banner_visual" v-if="page.template === 'banner_visual_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="ad" v-if="page.template === 'ad_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="house" v-if="page.template === 'house_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="product_photo" v-if="page.template === 'product_photo_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="product_presentation" v-if="page.template === 'product_presentation_overview'"></PortalMarketingItemOverview>
                    <PortalMarketingItemOverview item-type="pricelist" v-if="page.template === 'pricelist_overview'"></PortalMarketingItemOverview>

                </div>
                <div v-if="!page && !isLoading">
                    <p>Click on an item in the menu for more information.</p>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="order-2 order-md-1 mb-10 mb-md-0">
                <v-card class="offwhite-card marketing-nav" elevation="0">
                    <Navigation :active-page="page"></Navigation>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Navigation from "../components/marketing/Navigation";
import PortalMarketingItemOverview from "@/components/marketing/PortalMarketingItemOverview";

export default {
    name: "MarketingPageDetail",
    components: {
        Navigation,
        PortalMarketingItemOverview
    },
    data: () => ({
        page: null,
        isLoading: false,
        breadcrumbs: []
    }),
    mounted() {
        this.updatePage();
    },
    watch: {
        '$route'() {
            this.updatePage();
        },
    },
    methods: {
        updatePage() {
            this.page = null;

            this.breadcrumbs = [
                {
                    text: 'Home',
                    disabled: false,
                    to: { name: 'Home' },
                    exact: true
                },
                {
                    text: 'Marketing',
                    disabled: false,
                    to: { name: 'Marketing' },
                    exact: true
                }
            ];

            let slug = this.$route.params.slug;
            if (Array.isArray(slug)) {
                slug = slug.join('/');
            }
            if (slug) {
                this.isLoading = true;
                this.$store.dispatch('getMarketingPage', {slug: slug}).then(page => {
                    this.page = page;
                    this.isLoading = false;

                    this.breadcrumbs.push({
                        text: this.getCategoryTitle(page.category) + ' - ' + page.title,
                        disabled: true,
                        to: { name: 'MarketingPageDetail', params: { slug: page.slug } },
                    });

                    this.$store.dispatch('setPageTitle', page.title)

                    const el = this.$refs['marketingPageContent'];
                    if (el) {
                        el.scrollIntoView({behavior: 'smooth'});
                    }

                });
            }
        },
        getCategoryTitle(category) {
            switch (category) {
                case 'corporate':
                    return 'Corporate';
                case 'promotion':
                    return 'Promotion'
                case 'policies-prices':
                    return 'Policies & prices';
            }

            return ''
        }
    },
}
</script>

<style scoped>

</style>
