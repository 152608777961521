<template>
    <v-list v-if="menuPages">

        <v-list-group color="primary" :value="(activePage.category === 'corporate') ? true : false" no-action>
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title v-text="'Corporate'"></v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list class="py-0" v-if="menuPages.corporate.length > 0">
                <NavigationItem v-for="page in menuPages.corporate" :page="page" :key="'corporate-'+page.slug"></NavigationItem>
            </v-list>
        </v-list-group>

        <v-list-group color="primary" :value="(activePage.category === 'promotion') ? true : false">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title v-text="'Promotion'"></v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list class="py-0" v-if="menuPages.promotion.length > 0">
                <NavigationItem v-for="page in menuPages.promotion" :page="page" :key="'promotion-'+page.slug"></NavigationItem>
            </v-list>
        </v-list-group>

        <v-list-group color="primary" :value="(activePage.category === 'policies-prices') ? true : false">
            <template v-slot:activator>
                <v-list-item-content>
                    <v-list-item-title v-text="'Policies & prices'"></v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list class="py-0" v-if="menuPages.policiesPrices.length > 0">
                <NavigationItem v-for="page in menuPages.policiesPrices" :page="page" :key="'policies-prices-'+page.slug"></NavigationItem>
            </v-list>
        </v-list-group>

    </v-list>
</template>

<script>
import NavigationItem from "./NavigationItem";

export default {
    name: "Navigation",
    components: {NavigationItem},
    props: {
        activePage: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        menuPages: null
    }),
    mounted () {
        this.$store.dispatch('getMarketingMenuPages').then(pages => {
            let corporate = pages.filter(p => p.category === 'corporate');
            let promotion = pages.filter(p => p.category === 'promotion');
            let policiesPrices = pages.filter(p => p.category === 'policies-prices');

            this.menuPages = {
                'corporate': corporate,
                'promotion': promotion,
                'policiesPrices': policiesPrices
            };
        }).catch(() => {
            this.menuPages = [];
        })
    }
}
</script>

<style scoped>

</style>
