<template>
    <v-container>
        <v-row class="mb-3">
            <v-col cols="12" md="4" v-for="category in categories" :key="category.slug" class="d-flex flex-column">
                <v-card
                    block
                    class="offwhite-card marketing-card"
                    elevation="0"
                >
                    <v-img
                        :src="getCategoryImage(category)"
                        aspect-ratio="3"
                    ></v-img>
                    <v-card-title>
                        {{ category.name }}
                    </v-card-title>
                </v-card>
                <v-card
                    block
                    class="offwhite-card sector-category-card py-3 flex-fill"
                    elevation="0"
                >
                    <div
                        v-for="page in getCategoryPages(category)"
                        :key="page.slug"
                        class="sector-category"
                    >
                        <router-link
                            :to="{name:'MarketingPageDetail', params: { slug: page.slug }}">
                            <v-icon x-small color="accent" class="mr-3">fal fa-chevron-right</v-icon>
                            {{ page.title }}
                        </router-link>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "Marketing",
    data: () => ({
        menuPages: null
    }),
    computed: {
        ...mapState({
            categories: state => state.marketing.categories,
        })
    },
    mounted () {
        this.$store.dispatch('getMarketingMenuPages').then(menuPages => {
            this.menuPages = menuPages;
        }).catch(() => {
            this.menuPages = [];
        })
    },
    methods: {
        getCategoryImage(category) {
            return '/img/marketing/' + category.slug + '.png'
        },
        getCategoryPages(category) {
            if (!this.menuPages) {
                return [];
            }
            return this.menuPages.filter(c => c.category === category.slug);
        },
    }
}
</script>

<style scoped>

</style>
