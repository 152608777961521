<template>
    <div class="marketing-brochure-overview">
        <div class="row align-center">
            <v-col class="text-right">
                <strong class="px-4 flex-fill">Filters:</strong>
            </v-col>
            <v-col cols="3">
                <v-select
                    :items="languages"
                    label="Language"
                    v-model="selectedLanguages"
                    outlined
                    flat
                    multiple
                    clearable
                    hide-details
                    :menu-props="{
                       closeOnContentClick: true,
                       nudgeBottom: 58
                    }"
                ></v-select>
            </v-col>
        </div>

        <v-row class="mb-3">
            <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="item in paginatedItems" :key="'item-brochure-'+item.id">
                <BrochureCard :item="item"></BrochureCard>
            </v-col>
        </v-row>

        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="filteredItems.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </div>
</template>

<script>
import BrochureCard from "@/components/marketing/BrochureCard";

export default {
    name: "BrochureOverview",
    components: {BrochureCard},
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        selectedLanguages: null,
        pagination: {
            pageNumber: 1,
            itemsPerPage: 12
        }
    }),
    computed: {
        filteredItems() {
            if(this.selectedLanguages && this.selectedLanguages.length > 0) {
                return this.items.filter(i => this.selectedLanguages.includes(i.language));
            }

            return this.items;
        },
        pages() {
            return Math.ceil(this.filteredItems.length / this.pagination.itemsPerPage)
        },
        paginatedItems() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.filteredItems.slice(start, end);
        },
        languages() {
            let languages = [];

            this.items.forEach((item) => {
               languages.push({
                   text: this.getLanguageLabel(item.language),
                   value: item.language
               });
            });

            return languages;
        }
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        },
        getLanguageLabel(language) {
            switch (language) {
                case 'en':
                    return 'English';
                case 'nl':
                    return 'Dutch';
                case 'de':
                    return 'German';
                case 'fr':
                    return 'French';
            }

            return '';
        }
    }
}
</script>

<style scoped>

</style>
