<template>
    <v-card
        block
        outlined
        elevation="0"
        :href="item.url"
        target="_blank"
        rel="external"
        class="product-display-card"
    >
        <div class="image">
            <v-img
                :src="item.thumbnail.url"
                v-if="item.thumbnail"
            ></v-img>
            <p v-else class="icon mb-0 text-center"><v-icon color="primary">fal fa-sign</v-icon></p>
        </div>

        <p class="text-center ma-3">
            {{ item.title }}
            <small class="d-block text--secondary" v-if="item.product">{{ item.product }}</small>
        </p>
        <v-card-actions class="align-center grey lighten-3">
            <v-btn text small color="primary">View</v-btn>
            <v-spacer></v-spacer>
            <v-btn icon color="primary"><v-icon>fal fa-angle-right</v-icon></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "PromotionalProductCard",
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
