<template>
    <div class="marketing-item-overview">
<!--        <pre>{{items}}</pre>-->
        <v-row class="mb-3" v-if="items.length > 0 && isRegularOverview && !isLoading">
            <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="item in paginatedItems" :key="'item-'+itemType+'-'+item.id">
                <LogoCard :item="item" v-if="itemType === 'logo'"></LogoCard>
                <StickerCard :item="item" v-if="itemType === 'sticker'"></StickerCard>
                <CorporatePresentationCard :item="item" v-if="itemType === 'corporate_presentation'"></CorporatePresentationCard>
                <GatedContentCard :item="item" v-if="itemType === 'gated_content'"></GatedContentCard>
                <EventCard :item="item" v-if="itemType === 'event'"></EventCard>
                <PromotionalProductCard :item="item" v-if="itemType === 'promotional_product'"></PromotionalProductCard>
                <ProductDisplayCard :item="item" v-if="itemType === 'product_display'"></ProductDisplayCard>
                <HouseCard :item="item" v-if="itemType === 'house'"></HouseCard>
                <PricelistCard :item="item" v-if="itemType === 'pricelist' && $store.getters.canUserViewPrices"></PricelistCard>
            </v-col>
        </v-row>
        <v-row class="mb-3" v-if="items.length == 0 && isRegularOverview && !isLoading">
            <v-col cols="12">
                <v-alert
                  outlined
                  color="warning"
                  border="left"
                >
                    There are no items available.
                </v-alert>
            </v-col>
        </v-row>

        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="items.length > pagination.itemsPerPage && isRegularOverview"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>

        <PressReleaseOverview :items="items" v-if="itemType === 'press_release'"></PressReleaseOverview>
        <BrochureOverview :items="items" v-if="itemType === 'brochure'"></BrochureOverview>
        <AnimationVideoOverview :items="items" v-if="itemType === 'animation_video'"></AnimationVideoOverview>
        <BannerVisualOverview :items="items" v-if="itemType === 'banner_visual'"></BannerVisualOverview>
        <AdOverview :items="items" v-if="itemType === 'ad'"></AdOverview>
        <ProductPhotoOverview :items="items" v-if="itemType === 'product_photo'"></ProductPhotoOverview>
        <ProductPresentationOverview :items="items" v-if="itemType === 'product_presentation'"></ProductPresentationOverview>
    </div>
</template>

<script>
import LogoCard from "@/components/marketing/LogoCard";
import StickerCard from "@/components/marketing/StickerCard";
import CorporatePresentationCard from "@/components/marketing/CorporatePresentationCard";
import GatedContentCard from "@/components/marketing/GatedContentCard";
import EventCard from "@/components/marketing/EventCard";
import PromotionalProductCard from "@/components/marketing/PromotionalProductCard";
import ProductDisplayCard from "@/components/marketing/ProductDisplayCard";
import HouseCard from "@/components/marketing/HouseCard";
import PricelistCard from "@/components/marketing/PricelistCard";

import PressReleaseOverview from "@/components/marketing/PressReleaseOverview";
import BrochureOverview from "@/components/marketing/BrochureOverview";
import AnimationVideoOverview from "@/components/marketing/AnimationVideoOverview";
import BannerVisualOverview from "@/components/marketing/BannerVisualOverview";
import AdOverview from "@/components/marketing/AdOverview";
import ProductPhotoOverview from "@/components/marketing/ProductPhotoOverview";
import ProductPresentationOverview from "@/components/marketing/ProductPresentationOverview";

export default {
    name: "PortalMarketingItemOverview",
    components: {
        LogoCard,
        StickerCard,
        CorporatePresentationCard,
        GatedContentCard,
        EventCard,
        PromotionalProductCard,
        ProductDisplayCard,
        HouseCard,
        PricelistCard,
        PressReleaseOverview,
        BrochureOverview,
        AnimationVideoOverview,
        BannerVisualOverview,
        AdOverview,
        ProductPhotoOverview,
        ProductPresentationOverview
    },
    props: {
        itemType: {
            type: String,
            required: true
        }
    },
    data: () => ({
        items: [],
        isLoading: true,
        pagination: {
            pageNumber: 1,
            itemsPerPage: 12
        }
    }),
    computed: {
        isRegularOverview() {
            return this.itemType !== 'press_release' && this.itemType !== 'brochure' && this.itemType !== 'animation_video' && this.itemType !== 'banner_visual' && this.itemType !== 'ad' && this.itemType !== 'product_photo' && this.itemType !== 'product_presentation'
        },
        pages() {
            return Math.ceil(this.items.length / this.pagination.itemsPerPage)
        },
        paginatedItems() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.items.slice(start, end);
        }
    },
    mounted () {
        this.$store.dispatch('getMarketingItems', { type: this.itemType }).then(items => {
            this.items = items;
            this.isLoading = false;
        }).catch(() => {
            this.items = [];
            this.isLoading = false;
        })
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        }
    }
}
</script>

<style scoped>

</style>
